import {
  CallAssistCard,
  CallAssistCardContents,
  CallAssistCardFooter,
} from "src/components/CallAssistActivate/CallAssistActivateCommon"
import { useCallAssistActivateProvider } from "src/components/CallAssistActivate/CallAssistActivateContext"
import { CallAssistActivateError } from "src/components/CallAssistActivate/CallAssistActivateError"
import { CallAssistActivateSuccess } from "src/components/CallAssistActivate/CallAssistActivateSuccess"
import { CallAssistPriceTable } from "src/components/CallAssistActivate/CallAssistPriceTable"
import { isRecoverableError } from "src/components/CallAssistActivate/callAssistTypes"
import { getFormattedCallAssistCost } from "src/components/CallAssistActivate/getFormattedCallAssistCost"
import { WrongPlanContents } from "src/components/CallAssistActivate/WrongPlanContents"
import {
  usePostCallAssistAcitvationConfirmed,
  usePostCallAssistActivationInitiated,
} from "src/data/analytics/queries/CallAssistAnalyticsQueries"
import { usePatchCallAssistSubscription } from "src/data/callAssist/queries/callAssistQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { LearnMore } from "src/ui/Link/LearnMore"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"

export function CallAssistActivateContent() {
  const { t, langKeys } = useTranslate()
  const { initialLoading, callAssistAddonActivated, providerError } =
    useCallAssistActivateProvider()

  if (initialLoading) {
    return <LoadingScreen debugInfo={"Initializing CA data"} />
  }

  if (providerError === "wrong plan" || providerError === "bad_state") {
    return <WrongPlanContents />
  }

  if (callAssistAddonActivated && providerError !== "already_active") {
    return <CallAssistActivateSuccess />
  }

  return (
    <CallAssistCard
      heading={t(langKeys.activate_call_assist_title)}
      children={<Contents />}
      footer={<Footer />}
    />
  )
}

function Contents() {
  const { t, langKeys } = useTranslate()
  const { callAssistEstimate, providerError } = useCallAssistActivateProvider()

  const postCallAssistActivationInitiatedTrackingEvent =
    usePostCallAssistActivationInitiated()
  useEffectOnce(() => {
    postCallAssistActivationInitiatedTrackingEvent.mutate()
  })

  const { unitPriceMonthly } = callAssistEstimate
    ? getFormattedCallAssistCost(callAssistEstimate)
    : { unitPriceMonthly: null }

  const hideTable = !!providerError && !isRecoverableError(providerError)

  if (hideTable && !unitPriceMonthly) {
    return null
  }

  return (
    <CallAssistCardContents>
      {unitPriceMonthly && (
        <div>
          <span>
            {t(langKeys.activate_call_assist_body, { 0: unitPriceMonthly })}
          </span>{" "}
          <LearnMore href="https://www.minut.com/product/minut-monitoring" />
        </div>
      )}

      <CallAssistPriceTable hidden={hideTable} data={callAssistEstimate} />
    </CallAssistCardContents>
  )
}

function Footer() {
  const { providerError } = useCallAssistActivateProvider()
  const { t, langKeys } = useTranslate()
  const { user_id: userId } = useGetUser()
  const { goBack } = useRouter()

  const patchCallAssistSubcription = usePatchCallAssistSubscription({ userId })
  const postCallAssistActivationConfirmedTrackingEvent =
    usePostCallAssistAcitvationConfirmed()

  const cannotActivate = !!providerError && !isRecoverableError(providerError)
  const patchError = patchCallAssistSubcription.error?.response?.data.error_key

  function Error() {
    if (providerError)
      return <CallAssistActivateError errorType={providerError} />
    if (patchError) return <CallAssistActivateError errorType={patchError} />
    return null
  }

  return (
    <CallAssistCardFooter>
      <Error />

      <TextButton
        onClick={() => goBack({ defaultPath: Routes.Dashboard.location() })}
      >
        {t(langKeys.cancel)}
      </TextButton>

      <MButton
        disabled={cannotActivate}
        loading={patchCallAssistSubcription.isLoading}
        onClick={() => {
          postCallAssistActivationConfirmedTrackingEvent.mutate()
          patchCallAssistSubcription.mutate({ status: "active" })
        }}
      >
        {t(langKeys.activate)}
      </MButton>
    </CallAssistCardFooter>
  )
}
