import styled from "styled-components"

import {
  CallAssistCard,
  CallAssistCardFooter,
} from "src/components/CallAssistActivate/CallAssistActivateCommon"
import { useCallAssistActivateProvider } from "src/components/CallAssistActivate/CallAssistActivateContext"
import { getFormattedCallAssistCost } from "src/components/CallAssistActivate/getFormattedCallAssistCost"
import { HREF_MINUT_MARKETING_CALL_ASSIST_INFO } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function WrongPlanContents() {
  const { goBack } = useRouter()
  const { t, langKeys } = useTranslate()
  const { callAssistEstimate } = useCallAssistActivateProvider()

  const unitPriceMonthly = callAssistEstimate
    ? getFormattedCallAssistCost(callAssistEstimate).unitPriceMonthly
    : null

  return (
    <CallAssistCard
      heading={t(langKeys.activate_call_assist_upgrade_plan_title)}
      children={t(langKeys.activate_call_assist_upgrade_plan_body)}
      footer={
        <CallAssistCardFooter>
          <MBanner type="info" fullWidth>
            <AlertContents>
              <MText variant="subtitle">
                {t(langKeys.activate_call_assist_about_title)}
              </MText>
              <MText variant="body">
                {t(langKeys.activate_call_assist_about_body_1)}{" "}
                <LearnMore href={HREF_MINUT_MARKETING_CALL_ASSIST_INFO} />
              </MText>
              <MText variant="body">
                {unitPriceMonthly &&
                  t(langKeys.activate_call_assist_about_body_2, {
                    0: unitPriceMonthly,
                  })}
              </MText>
            </AlertContents>
          </MBanner>

          <TextButton
            onClick={() => goBack({ defaultPath: Routes.Dashboard.location() })}
          >
            {t(langKeys.cancel)}
          </TextButton>

          <MButton appHref={Routes.AccountBilling.location()}>
            {t(langKeys.subscription_dialog_positive_action)}
          </MButton>
        </CallAssistCardFooter>
      }
    />
  )
}

const AlertContents = styled.div`
  display: grid;
  gap: ${spacing.M};
`
